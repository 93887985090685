<template>
  <BaseLayout title="我的钱包" bg="#1989fa" fg="#FFF" notitle>
    
    <div class="summary">
        <div class="desc1">账户余额</div>
        <number-flip :number="state.stat.balance" fg="#FFF" size="22px"><span style="color:#CCC;font-size:9px;">USDT</span></number-flip>
        <div class="desc2">累计奖励：{{state.stat.reward}} U，累计消费：{{state.stat.consume}} U</div>
    </div>
    
    <div class="toolbar">
      <van-button class="btn" size="small" color="#39F" block to="/my/deposit">充值</van-button>
      <van-button class="btn" size="small" color="#39F" block to="/my/withdrawal">提现</van-button>
      <van-button class="btn" size="small" color="#39F" block @click="$toast('仅钻石会员可用')">划转</van-button>
    </div>

    <van-pull-refresh class="list" v-model="state.refreshing" @refresh="onChange">   
      <van-tabs v-model:active="state.active" color="#1989fa" offset-top="45" sticky swipeable animated @change="onChange">
        <van-tab v-for="(v,k) in state.category" :key="k" :title="v" >
          <van-list v-model:loading="state.loading" :finished="state.finished" loading-text="正在请求数据 ..." finished-text="没有更多了" :immediate-check="false" @load="onLoad">
            
            <van-empty v-show="state.list.length == 0"></van-empty>
            
            <van-cell-group class="block" :border="false" v-for="(item,index) in state.list" :key="index">
              <van-cell class="item" :border="false" :title="item.remark">
                <template #extra><span :style="{color:item.color}">{{item.amount}} U</span></template>
              </van-cell>
              <van-cell class="dt" :border="false" :title="item.dt" :value="'余额：' +  item.subtotal + ' U'" ></van-cell>
            </van-cell-group>

          </van-list>
        </van-tab>
      </van-tabs>
    </van-pull-refresh>

  </BaseLayout>
</template>

<style scoped>
.summary {
  margin: 0px 20px;
  padding: 5px 10px;
  background: #39F;
  border-radius: 5px;
  text-align: center;
}
.summary .desc1 {
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
.summary .desc2 {
  color: #FFF;
  font-size: 12px;
  opacity: 0.6;
}

.toolbar {
  margin: 10px 17px;
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.btn {
  margin: 3px;
}


.list {
  min-height: 500px;
  padding: 10px;
  background: #F9F9F9;
}
.block {
  margin-top: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.item {
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}
.dt {
  height: 30px;
  padding-top: 2px;
  font-size: 12px;
}

</style>

<script>
import { reactive } from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'
import NumberFlip from '@/components/NumberFlip.vue'

export default {
  components: {BaseLayout, NumberFlip},

  setup(){
    const state = reactive({
      stat: {
        balance: 0,
        freez: 0,
        transfer: 0,
        deposit: 0,
        withdrawal: 0,
        consume: 0,
        reward: 0,
      },

      active: 0, // 当前激活的标签
      refreshing: false,
      loading: false,
      finished: false,

      category: {
        all: "全部",
        reward: "奖励",
        consume: "消费",
        deposit: "充值",
        withdrawal: "提现",
        transfer: "划转",
      },

      list: [],   // 列表
      offset: 0,  // 记录偏移量
      limit : 20, // 每页记录数量
    });
    return {
      state,
    };
  },

  created() {
    this.onChange();
  },

  methods: {
    onChange() {
      this.state.loading = true;
      this.state.finished = false;
      this.state.offset = 0;
      this.state.list = [];
      this.onLoad();
      this.$http.get("/property/summary?subject=cash").then((data) => this.state.stat = data);
    },
    onLoad() {
      this.$http.get("/property/detail?subject=cash&type=" + Object.keys(this.state.category)[this.state.active] + "&offset=" + this.state.offset + "&limit=" + this.state.limit).then((data) => {
        this.state.refreshing = false;
        if (data.length == 0 || data.length < this.state.limit)
          this.state.finished = true;
        this.state.loading = false;
        this.state.offset += data.length;

        for (let i in data){
          let item = data[i];
          this.state.list.push({...item, ...{
            amount: item.amount.replace(/(\.\d\d)(\d+)$/, "$1"),
            subtotal: item.subtotal.replace(/(\.\d\d)(\d+)$/, "$1"),
            color : (item.amount > 0 ? "#090" : "#F66"),
          }});
        }
      });
    },
  }
}
</script>
